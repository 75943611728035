import React, { useState, useEffect } from "react";
import { getAboutUsApi } from "../../../api/user"
import "./Home.scss"

export function Home(props) {
    const { language } = props;
    const [aboutUsData, setAboutUsData] = useState(null);
    useEffect(() => {
        (async () => {
            const response = await getAboutUsApi(language);
            setAboutUsData(response);
            console.log(aboutUsData)

        })();
    }, [language]);



    return (
        <div className="Home-container">
            <div className="Home-container_info">
                <h1 className="Home-container_info__name">{aboutUsData ? aboutUsData[0].name : null}</h1>
            </div>

        </div>

    )
}