import React, { useState, useEffect } from "react";
import "./Team.scss"
import { BASE_PATH } from "../../../utils/constants";
import { getTeam } from "../../../api/Team";
export function Team(props) {
    const { language } = props;
    const [team, setTeam] = useState(null);
    useEffect(() => {
        (async () => {
            const response = await getTeam(language);
            setTeam(response);
            console.log(team[0].image[0].url)

        })();
    }, [language]);

    // Comprobación para asegurarse de que 'team' y 'team[0].image[0].url' estén disponibles
    if (!team || !team[0] || !team[0].image || !team[0].image[0].url) {
        return <div>Loading...</div>; // O algún otro manejo de estado de carga
    }

    return (
        <div className="Team-title">
            {/* <h1>{language == "en" ? "Our Team:" : "Nuestro Equipo:"}</h1>{" "} */}
            <div className="Team-container">

                <div className="Team-container_info">
                    <div className="image">
                        <img src={`${BASE_PATH}${team[0].image[0].url}`} alt="Team Member" />
                    </div>
                    <div className="Team-container_info__name">
                        <a className="header">{team[0].name} {team[0].surname}</a>
                        <div className="container_info__description">
                            {team[0].name} is an {team[0].studies}.
                        </div>
                    </div>
                    <div className="extra content">

                    </div>
                </div>

                <div className="Team-container_info">
                    <div className="image">
                        <img src={`${BASE_PATH}${team[1].image[0].url}`} alt="Team Member" />
                    </div>
                    <div className="Team-container_info__name">
                        <a className="header">{team[1].name} {team[1].surname}</a>
                        <div className="container_info__description">
                            {team[1].name} is an {team[1].studies}.
                        </div>
                    </div>
                    <div className="extra content">

                    </div>
                </div>
            </div>
        </div>

    );
}