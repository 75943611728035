import { BASE_PATH } from "../utils/constants";

export async function getAboutApi(language) {
  try {
    const url = `${BASE_PATH}/abouts`;
    console.log(url)
    const response = await fetch(url);
    const result = await response.json();
    console.log("language = ", language);
    if (language == "en") {
      const filteredResult = result.filter(item => item.language === "en");
      console.log("en = ", filteredResult)

      return filteredResult;

    }
    else {
      const filteredResult = result.filter(item => item.language === "es");
      console.log("es = ", filteredResult)
      return filteredResult;


    }
  } catch (error) {
    console.log(error);
    return null;
  }
}