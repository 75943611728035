



import "./ContactForm.scss"
import React, { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendEmail as sendData } from "../../api/sendEmail";

import UseEmail from "../../api/useEmail";


export function ContactForm() {

    const {
        loading,
        submitted,
        error,
        sendEmail
    } = UseEmail("https://public.herotofu.com/v1/7614c1c0-b471-11ee-ae0b-a7e011fe96d3");


    const sendExample = () => {
        // Can be any data, static and dynamic
        sendData({
            example_user: "smash2winllc@gmail.com",
            example_data: new Date().toISOString(),
        });
    };


    const [identifierValue, setIdentifierValue] = useState("");
    const [textValue, setTextValue] = useState("");

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) => {
            console.log(formData)
            console.log(formik)
            const response = await sendEmail(formData);
            console.log("sendingEmail");
            setIdentifierValue("")
            setTextValue("")
            formik.values.identifier = "";
            formik.values.text = ""
            toast.success('your email has been sent correctly', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        },
    });

    return (
        <div className="contact-form-container">

            <ToastContainer />
            <Form className="contact-form-container_texts" onSubmit={formik.handleSubmit}>

                <Form.Input
                    label="Email:"
                    name="identifier"
                    type="text"
                    placeholder="your email"
                    onChange={formik.handleChange}
                    value={formik.values.identifier}
                    error={formik.errors.identifier}
                />
                <Form.TextArea
                    label="Text:"
                    name="text"
                    type="text"
                    placeholder="text"
                    onChange={formik.handleChange}
                    value={formik.values.text}
                    error={formik.errors.text}
                />

                <div className="contact-form-container_texts_actions">
                    <Button className="submit" type="submit" onClick={sendExample}>
                        Send
                    </Button>

                </div>
            </Form>
           

        </div>

    )
}

function initialValues() {
    return {
        identifier: "",
        text: "",
    };
}

function validationSchema() {
    return {
        identifier: Yup.string().email(true).required(true),
        text: Yup.string().required(true),
    };
}