import React from "react";
import { Card, Icon } from "semantic-ui-react";
import "./Contact.scss"
import { useState, useEffect } from "react";
import { getAboutUsApi } from "../../../api/user";
import { ContactForm } from "../../../Components/Contact/ContactForm";
export function Contact(props) {
    const { language } = props;

    return (
        <div className="contact-container">
            <div className="contact-container_title"><h1>{language == "en" ? "Get in touch:" : "Contacto:"}</h1></div>
            <div className="contact-container_form">
                <ContactForm />
            </div>
        </div>

    )
}