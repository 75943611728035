import React, { useState, useEffect } from "react";
import "./Courses.scss"
import {getCourses} from "../../../api/getCourses"
import { marked } from 'marked';
import { Helmet } from 'react-helmet';
import {  Loader } from "semantic-ui-react";


export function Courses(props) {
    const [policy, setPolicy] = useState(null)
    const { language } = props;


    useEffect(() => {
        (async () => {
            const response = await getCourses();
            setPolicy(response);
            console.log("policy = ",response)
        })();
    }, []);

    if (!policy) return <Loader active inline="centered" />

    return (
        <div className="skills-container">
            <div className="skills-container-div">
                <div className="skills-container-div__languages__title"><h1>{language == "en" ? "Policy Terms:" : "Policy:"}</h1></div>
                <div className="post_content" dangerouslySetInnerHTML={{ __html: marked(policy[0].policy ) }}/>
            </div>
        </div>

    )
}