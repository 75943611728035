import React, { useState, useEffect } from "react";
import { getAboutApi } from "../../../api/AboutUs";
import "./About.scss"
import { AboutMe } from "../../../Components/About/AboutGroup/AboutMe";
export function About(props) {
    const [aboutUsData, setAboutUsData] = useState(null);
    
    const { language } = props;

    useEffect(() => {
        (async () => {
            const response = await getAboutApi(language);
            setAboutUsData(response);
            console.log("aboutUs Data = ",aboutUsData)
        })();
    }, [language]);

  

   return (
    
        <div className="About-container">
            <div className="About-container__AboutMe">

                <div className="About-container__AboutMe__description">
                    <AboutMe aboutUsData={aboutUsData} language={language} />
                </div>
            </div>

        </div>

    )
}