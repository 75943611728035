import "./NotGoodScreen.scss"
import { Icon } from 'semantic-ui-react'
export function NotGoodSCreen() {

    return (
        <div className="not-good-screen">
            <h1 className="not-good-screen_h1">Try to open it in computer or press the <Icon name='ellipsis vertical' /> button and check the computer view.</h1>
        </div>

    )
}