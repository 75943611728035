import { BASE_PATH } from "../utils/constants";

export async function getCourses() {
    try {
        const url = `${BASE_PATH}/policy-terms`;
        const response = await fetch(url);
        const result = await response.json();
        console.log(result);
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}