import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react"
import "./ButtonGroup.scss"
import { Image, Button, Icon } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { BASE_PATH } from "../../utils/constants"
import { getCurriculum } from "../../api/getCurriculum";

export function ButtonGroup() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [error, setError] = useState(null);

  const [curriculumUrl, setCurriculumUrl] = useState(null)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (

    <div className="button-container">
      {screenWidth > 768 ? (
        <>
          <Button.Group vertical className="button-container__home">
            <Link to="/">
              <a>
                <Button className="buttonLayout-home" animated="fade">
                  <Button.Content visible><Icon name='home' size="big" /></Button.Content>
                  <Button.Content hidden color="orange">
                    Home
                  </Button.Content>
                </Button>
              </a>
            </Link>
            <Link to="/About">

              <Button className="buttonLayout" animated="fade">
                <Button.Content visible ><Icon name='user' size="big" /></Button.Content>
                <Button.Content hidden >
                  About Us
                </Button.Content>
              </Button>
            </Link>

            <Link to="/policy-terms">

              <Button className="buttonLayout" animated="fade">
                <Button.Content visible><Icon name='book' size="big" /></Button.Content>
                <Button.Content hidden>
                  Policy
                </Button.Content>
              </Button>
            </Link>


            <Link to="/Contact">
              <Button className="buttonLayout" animated="fade">
                <Button.Content visible><Icon name='at' size="big" /></Button.Content>
                <Button.Content hidden>
                  Contact
                </Button.Content>
              </Button>
            </Link>

            <Link to="/Team">
              <Button className="buttonLayout-download" animated="fade">
                <Button.Content visible><Icon name='users' size="big" /></Button.Content>
                <Button.Content hidden>
                  Team
                </Button.Content>
              </Button>
            </Link>
           

          </Button.Group>
        </>) : (
        <>
          <Button.Group vertical className="button-container__home">
            <Link to="/">
              <a>
                <Button className="buttonLayout" animated="fade">
                  <Button.Content visible><Icon name='home' /></Button.Content>
                  <Button.Content hidden>
                    Home
                  </Button.Content>
                </Button>
              </a>
            </Link>
            <Link to="/About">
              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='user outline' /></Button.Content>
                <Button.Content hidden>
                  About Us
                </Button.Content>
              </Button>
            </Link>
            <Link to="/Blog">
              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='edit' /></Button.Content>
                <Button.Content hidden>
                  Blog
                </Button.Content>
              </Button>
            </Link>
            <Link to="/Contact">
              <Button className="buttonLayout" animated>
                <Button.Content visible><Icon name='at' /></Button.Content>
                <Button.Content hidden>
                  Contact
                </Button.Content>
              </Button>
            </Link>
            <Link to="/Team">
              <Button className="buttonLayout-download" animated="fade">
                <Button.Content visible><Icon name='users' size="big" /></Button.Content>
                <Button.Content hidden>
                  Team
                </Button.Content>
              </Button>
            </Link>
          </Button.Group>
        </>
      )}
    </div>
  );
}