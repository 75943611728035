import React from "react";
import "./AboutMe.scss";
import { Loader } from "semantic-ui-react";
import { marked } from "marked";

export function AboutMe(props) {
  const { aboutUsData, language } = props;
  console.log(aboutUsData)
  if (!aboutUsData) return <Loader active inline="centered" />;


  return (
    <div className="Home-container__aboutMe__description">
      <div className="About-container__aboutMe__description__pack">
        <div className="About-container__aboutMe__description__pack__1">
          <h1>{language == "en" ? "About Us:" : "Sobre Nosotros:"}</h1>{" "}
        </div>
        <div
          className="About-container__aboutMe__description__pack__2"
          dangerouslySetInnerHTML={{
            __html: marked(aboutUsData[0].aboutText),
          }}
        ></div>
      </div>
    </div>
  );
}
